{
  "country": "NL",
  "language": "nl",
  "availableLanguages": [
    "nl"
  ],
  "staticLinkBoxes": [
    {
      "text": "Voor particulieren",
      "link": "https://www.viessmann.nl/nl/services/product-informatie-voor-particulieren.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Voor Viessmann installateurs",
      "link": "https://www.viessmann.nl/nl/services/product-informatie-voor-installateurs.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
