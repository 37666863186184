import { provideRouter } from '@angular/router';
import { HttpBackend, provideHttpClient } from '@angular/common/http';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { importProvidersFrom} from '@angular/core';
import { ApplicationConfig } from '@angular/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { MaintenanceGuard } from './maintenance/maintenance.guard';
import { MaintenanceService } from './maintenance/maintenance.service';

export function createTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
      // your app i18n assets
      {
          prefix: './assets/i18n/locale_',
          suffix: '.json'
      },
      // @vi-succ/design-system-web assets
      {
          prefix: 'https://api.viessmann.com/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/',
          suffix: '/download?file_format=simple_json'
      }
  ]);
}
export const provideTranslation = () => ({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpBackend],
    },
  });

  export const appConfig: ApplicationConfig = {
    providers: [
      provideRouter(routes),
      provideAnimationsAsync(),
      provideHttpClient(),
      environment.ENV_PROVIDERS,
      MaintenanceService,
      MaintenanceGuard,
      importProvidersFrom(HttpClientModule), 
      importProvidersFrom([
        TranslateModule.forRoot(provideTranslation()),
        TranslatePipe
    ]),
  ],
  };