{
  "country": "BG",
  "language": "bg",
  "availableLanguages": [
    "bg"
  ],
  "staticLinkBoxes": [
    {
      "text": "за ползвател на инсталация",
      "link": "https://www.viessmann.bg/bg/services/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "за партньор на Viessmann",
      "link": "https://www.viessmann.bg/bg/services/produktinfo-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
