{
  "country": "FI",
  "language": "fi",
  "availableLanguages": [
    "fi"
  ],
  "staticLinkBoxes": [
    {
      "text": "Laitteen käyttäjälle",
      "link": "https://www.viessmann.fi/fi/palvelut/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    }
  ]
}
