import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ProductInfoService } from './product-info.service';

@Injectable({ providedIn: 'root' })
export class ProductInfoResolver implements Resolve<any> {
  constructor(
    private productInfoService: ProductInfoService
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (route.params['language']) {
      return this.productInfoService.getBySerialAndLanguage(route.params['id'], route.params['language']);
    } else {
      return this.productInfoService.getBySerial(route.params['id']);
    }
  }
} 
