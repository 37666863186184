{
  "country": "US",
  "language": "en",
  "availableLanguages": [
    "en"
  ],
  "staticLinkBoxes": [
    {
      "text": "Homeowner",
      "link": "https://www.viessmann-us.com/en/services/product_info_homeowner.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Contractor",
      "link": "https://www.viessmann-us.com/en/services/product_info_contractor.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
