import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProductInfoModel} from './product-info-model';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({providedIn: 'root'})
export class ProductInfoService {

  constructor(
    private http: HttpClient
  ) {
  }


  public getBySerial(id: string): Observable<ProductInfoModel> {
    var url = API_URL + '/serial-numbers/' + id;
    return this.http.get<ProductInfoModel>(url);
  }

  public getBySerialAndLanguage(id: string, language: string): Observable<ProductInfoModel> {
    var url = API_URL + '/serial-numbers/' + id + "?language=" + language;
    return this.http.get<ProductInfoModel>(url);
  }
}
