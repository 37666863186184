{
  "country": "DE",
  "language": "de",
  "availableLanguages": [
    "de"
  ],
  "staticLinkBoxes": [
    {
      "text": "Anlagenbetreiber / Inhaber",
      "link": "https://www.viessmann.de/de/services/produktregistrierung-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Fachpartner",
      "link": "https://www.viessmann.de/de/services/produktregistrierung-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
