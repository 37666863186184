{
  "country": "HR",
  "language": "hr",
  "availableLanguages": [
    "hr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Vlasnik instalacije",
      "link": "https://www.viessmann.hr/hr/services/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Viessmann stručni partner",
      "link": "https://www.viessmann.hr/hr/services/produktinfo-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
