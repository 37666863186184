{
  "country": "RO",
  "language": "ro",
  "availableLanguages": [
    "ro"
  ],
  "staticLinkBoxes": [
    {
      "text": "Pentru beneficiarii echipamentului",
      "link": "https://www.viessmann.ro/ro/services/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Pentru specialiştii Viessmann",
      "link": "https://www.viessmann.ro/ro/services/produktinfo-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
