import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProductInfoModel} from '../product-info-model';
import {Observable} from 'rxjs';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'not-sold',
  styleUrls: [
    './not-sold.component.scss'
  ],
  standalone: true,
  imports: [NgIf,NgFor],
  templateUrl: './not-sold.component.html'
})
export class NotSoldComponent implements OnInit {
  productInfo: any = [];
  public staticLinkBoxes: any;

  constructor(
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit() {
    this.productInfo = this.route.snapshot.data['productInformation'];
    console.log("this.productInfo: ", this.productInfo);
    try {
      this.staticLinkBoxes = require('../../assets/data/default.json').staticLinkBoxes;
    } catch (error) {
      this.staticLinkBoxes = [];
    }

  }
}
