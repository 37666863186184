<div class="imageStage">
    <div class="content" fxLayout="row" fxLayout.lt-sm="column" ngClass.lt-sm="small">
      <div class="image" fxFlex="200px">
        <img src="{{ productInfo.imageUrl || 'assets/img/noimage.png' }}">
      </div>
      <div class="text">
        <h1 *ngIf="productInfo.productName" ngClass.lt-sm="small">
          {{ productInfo.productName }}, {{ productInfo.productType }}</h1>
        <h1 *ngIf="!productInfo.productName" ngClass.lt-sm="small">
          {{ productInfo.description }}</h1>
        <p>
          Serial Number: {{ productInfo.number }}<br>
          <strong>PRODUCT NOT SOLD</strong>
        </p>
      </div>
    </div>
  </div>
  
  <div class="content" style="margin-top: 0px;">
    <div *ngIf="staticLinkBoxes.length <= 2" class="tiles md-padding flex-container">
      <div *ngFor="let staticLinkBox of staticLinkBoxes" class="tile flex-item" style="width: 100%;">
        <a class="headline" href="{{staticLinkBox.link.replace('{serial}', productInfo.number)}}" target="_blank">
          <div [style.backgroundImage]="'url(' + staticLinkBox.image + ')'" class="tile-content large" style="width: 100%;">
            <div class="teaser-box">
              <span class="headline">{{staticLinkBox.text}}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  
    <div *ngIf="staticLinkBoxes.length > 2" class="tiles md-padding flex-container">
      <div *ngFor="let staticLinkBox of staticLinkBoxes" class="tile flex-item" 
           style="flex: 1 1 100%; flex-basis: 100%; flex-basis: calc(25% - 10px); min-width: 300px;">
          <a class="headline" href="{{staticLinkBox.link.replace('{serial}', productInfo.number)}}" target="_blank">
            <div [style.backgroundImage]="'url(' + staticLinkBox.image + ')'" class="tile-content">
            <div class="teaser-box">
              <span class="headline">{{staticLinkBox.text}}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div> 