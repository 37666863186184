import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MaintenanceService} from './maintenance.service';
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {

  constructor(private maintenanceService: MaintenanceService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.maintenanceService.fetchStatus().pipe(
      
      map(
        (status) => {
          switch (status.status) {
            case 'major_outage':
            case 'under_maintenance': {
              this.router.navigate(['status/maintenance']);
              return false;
            }
            default:
              return true;
          }
        }
      )
    );
  }
}
