import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ProductInfoModel } from '../product-info-model';
import { ProductInfoService } from '../product-info.service';
import { TranslationService } from '../translation.service';

@Injectable({
  providedIn: 'root' // Use 'providedIn' instead of adding to providers array in module
})
export class ProductInfoGuard implements CanActivate {

  constructor(private productInfoService: ProductInfoService,
              private router: Router,
              private translationService: TranslationService
            ) {
  }

  public canActivate(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> {
    return this.productInfoService.getBySerial(next.params['id']).pipe(
      switchMap((data: ProductInfoModel) => {
        if (data.isSoldToCustomer && data.salesOrganization) {
          this.translationService.setLanguage(next.params['language']);
          // Check if sales organization and language params are present in the route
          if (next.params['id'] && next.params['salesOrganization'] && next.params['language']) {
            return of(true);
          } else {
            this.router.navigate(
              ['product-information', data.salesOrganization, data.language, next.params['id']]
            );
            return of(true);
          }
        } else {
          this.router.navigate(['product-information/not-sold', next.params['id']]);
          return of(false);
        }
      }),
      catchError(() => {
        // Handle error and navigate to the not-found page
        this.router.navigate(['product-information/not-found', next.params['id']]);
        return of(false);
      })
    );
  }
}
