{
  "country": "TR",
  "language": "tr",
  "availableLanguages": [
    "tr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Devreye alma, servis talebi veya Viessmann Partner Plus puan kazanımı için tıklayınız",
      "link": "https://serviskayit.viessmann.com.tr/serviceapp/device/{serial}",
      "image": "assets/img/qr-code.jpg"
    },
    {
      "text": "Ürün Sahipleri İçin",
      "link": "https://www.viessmann.com.tr/tr/hizmetler/urun-sahipleri-icin-urun-bilgisi.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Viessmann İş Ortakları için",
      "link": "https://www.viessmann.com.tr/tr/hizmetler/is-ortaklari-icin-urun-bilgisi.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
