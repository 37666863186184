{
  "country": "BE",
  "language": "nl",
  "availableLanguages": [
    "nl",
    "fr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Voor particulieren",
      "link": "https://www.viessmann.be/nl/services/productinformatie-voor-particulieren.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Voor Viessmann installateurs",
      "link": "https://www.viessmann.be/nl/services/productinformatie-voor-professionelen.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
