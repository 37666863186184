import {Routes} from '@angular/router';
import {ProductInfoComponent} from './product-info/product-info.component';
import {ProductInfoGuard} from './product-info/product-info-guard';
import {ProductInfoResolver} from './product-info.resolver';
import {NotSoldComponent} from './not-sold/not-sold.component';
import {NotFoundComponent} from "./not-found/not-found.component";
import {AppComponent} from "./app.component";
import {MaintenanceComponent} from "./maintenance/maintenance.component";
import {MaintenanceGuard} from "./maintenance/maintenance.guard";
import { MaintenanceBannerComponent } from './maintenance-banner/maintenance-banner.component';


export const routes: Routes = [
  {path: '', redirectTo: 'product-information', pathMatch: 'full'},
  {path: 'serialNo/:id', redirectTo: 'product-information/:id', pathMatch: 'full'},
  {path: 'serialno/:id', redirectTo: 'product-information/:id', pathMatch: 'full'},
  {
    path: 'product-information',
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: ':id',
        component: AppComponent,
        canActivate: [ProductInfoGuard]
      },
      {
        path: ':salesOrganization/:language/:id',
        component: ProductInfoComponent,
        resolve: {
          productInformation: ProductInfoResolver
        }
      },
      {
        path: 'not-sold/:id',
        component: NotSoldComponent,
        resolve: {
          productInformation: ProductInfoResolver
        }
      }
    ]
  },
  {
    path: 'status/maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'status/maintenanceBanner',
    component: MaintenanceBannerComponent
  },
  {
    path: 'not-found/:id',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];