{
  "country": "GB",
  "language": "en",
  "availableLanguages": [
    "en"
  ],
  "staticLinkBoxes": [
    {
      "text": "For end users",
      "link": "https://www.viessmann.co.uk/support/services/product-info/homeowners?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "For installers",
      "link": "https://www.viessmann.co.uk/support/services/product-info/installers?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
