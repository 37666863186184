import { CommonModule, NgIf } from '@angular/common';
import { MatChipListbox, MatChipsModule } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslationService } from '../translation.service';
import { ProductInfoService } from '../product-info.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-language-changer',
  templateUrl: './language-changer.component.html',
  styleUrls: ['./language-changer.component.scss'],
  standalone: true,
  imports: [MatChipsModule, MatChipListbox, NgIf, CommonModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LanguageChangerComponent implements OnInit, OnDestroy {
  availableLanguages: string[] = [];
  salesOrganization: string = '';
  language: string = '';
  country: string = '';
  serialNumber: string = '';
  
  private subscriptions: Subscription = new Subscription();

  constructor(
    private translationService: TranslationService,
    private productInfoService: ProductInfoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    // Abonniere das Observable für availableLanguages
    this.subscriptions.add(
      this.translationService.availableLanguages$.subscribe(languages => {
        this.availableLanguages = languages;
      })
    );

    this.subscriptions.add(
      this.translationService.serialNumber$.subscribe(serial => {
        this.serialNumber = serial;
      })
    );

    // Abonniere das Observable für salesOrganization
    this.subscriptions.add(
      this.translationService.salesOrganization$.subscribe(organization => {
        this.salesOrganization = organization;
      })
    );

    // Abonniere das Observable für language
    this.subscriptions.add(
      this.translationService.language$.subscribe(language => {
        this.language = language;
      })
    );

    // Abonniere das Observable für country
    this.subscriptions.add(
      this.translationService.country$.subscribe(country => {
        this.country = country;
      })
    );
  }

  ngOnDestroy() {
    // Unsubscribe von allen Subscriptions, um Speicherlecks zu vermeiden
    this.subscriptions.unsubscribe();
  }
}
