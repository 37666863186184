{
  "country": "SK",
  "language": "sk",
  "availableLanguages": [
    "sk"
  ],
  "staticLinkBoxes": [
    {
      "text": "Pre konečných užívateľov",
      "link": "https://www.viessmann.sk/sk/sluzby/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Pre obchodných partnerov",
      "link": "https://www.viessmann.sk/sk/sluzby/produktinfo-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
