import { Component, OnInit, OnDestroy } from '@angular/core';
import { MaintenanceService, PlannedMaintenance } from '../maintenance/maintenance.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-maintenance-banner',
  standalone: true,
  imports:[CommonModule],
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss']
})
export class MaintenanceBannerComponent implements OnInit, OnDestroy {
  public next_maintenance: PlannedMaintenance | undefined;
  private subscription: Subscription | undefined;

  constructor(private maintenanceService: MaintenanceService) {}

  ngOnInit(): void {
    this.subscription = this.maintenanceService.fetchStatus().subscribe({
      next: (status) => {
        this.next_maintenance = status.next_maintenance;
      },
      error: (error) => {
        console.error('Error fetching maintenance status:', error);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
