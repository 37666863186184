{
  "country": "ES",
  "language": "es",
  "availableLanguages": [
    "es"
  ],
  "staticLinkBoxes": [
    {
      "text": "Particulares",
      "link": "https://www.viessmann.es/es/servicios/informacion-producto-particulares.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Profesionales",
      "link": "https://www.viessmann.es/es/servicios/informacion-producto-profesionales.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
