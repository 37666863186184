import {Component, Input} from '@angular/core';

@Component({
  selector: 'flag',
  standalone: true,
  styleUrls: [
    './flag.component.scss'
  ],
  templateUrl: './flag.component.html'
})
export class FlagComponent {
  @Input() public country: String = "";

  constructor() {
  }

  public ngOnInit() {
  }
}
