{
  "country": "DK",
  "language": "da",
  "availableLanguages": [
    "da"
  ],
  "staticLinkBoxes": [
    {
      "text": "Indehaver",
      "link": "https://www.viessmann.dk/da/tjenester-og-support/produktinfo-til-ejer-af-varmesystem.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Installatør",
      "link": "https://www.viessmann.dk/da/tjenester-og-support/produktinfo-til-installatoer.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
