{
  "country": "SI",
  "language": "sl",
  "availableLanguages": [
    "sl"
  ],
  "staticLinkBoxes": [
    {
      "text": "za lastnike naprav",
      "link": "https://www.viessmann.si/sl/storitve/registracija-naprave-lastnik-naprave.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "za strokovna podjetja",
      "link": "https://www.viessmann.si/sl/storitve/registracija-proizvoda-ZH.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
