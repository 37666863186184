import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { DswFooterComponent } from '@vi-succ/design-system-web';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { MaintenanceService } from './maintenance/maintenance.service';
import { MaintenanceGuard } from './maintenance/maintenance.guard';
import { ProductInfoComponent } from './product-info/product-info.component';
import { TranslationService } from './translation.service';
import { LanguageChangerComponent } from './language-changer/language-changer.component';
import { Subscription } from 'rxjs';
import { MaintenanceBannerComponent } from './maintenance-banner/maintenance-banner.component';


@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NotFoundComponent, DswFooterComponent, TranslateModule, NgFor, NgIf, MatChipsModule, MatToolbarModule, MaintenanceComponent, ProductInfoComponent, LanguageChangerComponent, MaintenanceBannerComponent],
  providers: [MaintenanceService, MaintenanceGuard, TranslationService],
  templateUrl: './app.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppComponent {
  public language: string = '';
  public title = "QR-Code Landingpage";

  private subscriptions: Subscription = new Subscription();
  

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {

    this.subscriptions.add(
      this.translationService.language$.subscribe(language => {
        this.language = language;
      })
    );
  }
}


