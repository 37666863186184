{
  "country": "IT",
  "language": "it",
  "availableLanguages": [
    "it"
  ],
  "staticLinkBoxes": [
    {
      "text": "per i privati",
      "link": "https://www.viessmann.it/it/Servizi/informazioni-prodotto-per-privati.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "per gli installatori",
      "link": "https://www.viessmann.it/it/Servizi/informazioni-prodotto-per-installatori.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
