{
  "country": "RS",
  "language": "sr",
  "availableLanguages": [
    "sr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Za vlasnike postrojenja",
      "link": "https://www.viessmann.rs/sr/servisi/informacije-o-proizvodu-za-vlasnike-grejanja.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Za Viessmann stručne partnere",
      "link": "https://www.viessmann.rs/sr/servisi/informacije-o-proizvodu-za-specijalizovane-firme.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
