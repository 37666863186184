import {Component} from '@angular/core';

@Component({
  selector: 'not-found',
  standalone: true,
  styleUrls: [
    './not-found.component.scss'
  ],
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent {
}
