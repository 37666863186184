{
  "country": "DEFAULT",
  "language": "en",
  "availableLanguages": [
    "en"
  ],
  "staticLinkBoxes": [
    {
      "text": "Register product",
      "link": "https://registration.viessmann.com",
      "image": "assets/img/certificate.png"
    },
    {
      "text": "Access product documentation",
      "link": "https://webapps.viessmann.com/vibooks",
      "image": "assets/img/manuals.png"
    },
    {
      "text": "Product references",
      "link": "https://www.viessmann.com/com/en/references.html",
      "image": "assets/img/references.png"
    },
    {
      "text": "Contact",
      "link": "https://www.viessmann.com/com/en/contact.html",
      "image": "assets/img/viessmann-allendorf-flags.png"
    }
  ]
}
