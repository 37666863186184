import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private availableLanguagesSubject = new BehaviorSubject<string[]>(['de']);
  availableLanguages$ = this.availableLanguagesSubject.asObservable();

  private salesOrganizationSubject = new BehaviorSubject<string>('0500');
  salesOrganization$ = this.salesOrganizationSubject.asObservable();

  private languageSubject = new BehaviorSubject<string>('de');
  language$ = this.languageSubject.asObservable();

  private countrySubject = new BehaviorSubject<string>('DE');
  country$ = this.countrySubject.asObservable();

  private serialNumberSubject = new BehaviorSubject<string>('');
  serialNumber$ = this.serialNumberSubject.asObservable();

  constructor(
    private translate: TranslateService
  ) {}

  setAvailableLanguages(languages: string[]) {
    this.availableLanguagesSubject.next(languages);
  }

  setSalesOrganization(organization: string) {
    this.salesOrganizationSubject.next(organization);
  }

  setLanguage(language: string) {
    this.languageSubject.next(language);
  }

  setCountry(country: string) {
    this.countrySubject.next(country);
  }

  setSerialNumber(serial: string) {
    this.serialNumberSubject.next(serial);
  }

  getAvailableLanguages(): string[] {
    return this.availableLanguagesSubject.getValue();
  }

  getSerialNumber(): string {
    return this.serialNumberSubject.getValue();
  }

  getSalesOrganization(): string {
    return this.salesOrganizationSubject.getValue();
  }

  getLanguage(): string {
    return this.languageSubject.getValue();
  }

  getCountry(): string {
    return this.countrySubject.getValue();
  }
}
