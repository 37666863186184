{
  "country": "CH",
  "language": "fr",
  "availableLanguages": [
    "de",
    "fr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Pour les particuliers",
      "link": "https://www.viessmann.ch/de/services/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Pour les professionnels",
      "link": "https://www.viessmann.ch/de/services/produktinfo-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
