{
  "country": "LU",
  "language": "fr",
  "availableLanguages": [
    "de",
    "fr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Pour les particuliers",
      "link": "https://www.viessmann.lu/fr/services/informations-produits-proprietaires.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Pour les professionnels",
      "link": "https://www.viessmann.lu/fr/services/informations-produits-professionnels.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
