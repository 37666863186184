{
  "country": "RU",
  "language": "ru",
  "availableLanguages": [
    "ru"
  ],
  "staticLinkBoxes": [
    {
      "text": "Пользователь",
      "link": "https://viessmann.ru/ru/services/informaciya-o-produkte-dlya-polzovatelya.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Специалист",
      "link": "https://viessmann.ru/ru/services/informaciya-o-produkte-dlya-specialistov.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
