{
  "country": "CZ",
  "language": "cs",
  "availableLanguages": [
    "cs"
  ],
  "staticLinkBoxes": [
    {
      "text": "Pro koncové uživatele",
      "link": "https://www.viessmann.cz/cs/servis/informace-o-produktech-pro-majitele-vytapecich-systemu.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Pro obchodní partnery",
      "link": "https://www.viessmann.cz/cs/servis/informace-o-produktech-pro-odborne-partnery.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
