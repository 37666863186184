{
  "country": "FR",
  "language": "fr",
  "availableLanguages": [
    "fr"
  ],
  "staticLinkBoxes": [
    {
      "text": "Pour les particuliers",
      "link": "https://www.viessmann.fr/fr/services/informations-produits-proprietaires?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Pour les professionnels",
      "link": "https://www.viessmann.fr/fr/services/informations-produits-professionnels?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
