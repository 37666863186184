import { Component, OnInit, OnDestroy } from '@angular/core';
import { MaintenanceService, PlannedMaintenance } from './maintenance.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  host: {
    class: 'd-flex flex-column flex-1'
  },
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  imports: []
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  public title: string = "";
  public description: string = "";
  public isLoading = true;
  public next_maintenance: PlannedMaintenance | undefined;
  private subscription: Subscription | undefined;

  constructor(private maintenanceService: MaintenanceService) {}
  
  ngOnInit(): void {
    this.subscription = this.maintenanceService.fetchStatus().subscribe({
      next: (status) => {
        this.updateStatus(status);
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching maintenance status:', error);
        this.title = "Error";
        this.description = "Unable to fetch status at this time. Please try again later.";
        this.isLoading = false;
      }
    });
  }

  private updateStatus(status: { status: string; next_maintenance?: PlannedMaintenance }): void {
    switch (status.status.toUpperCase()) {
      case "MAJOR_OUTAGE":
        this.title = "Outage";
        this.description = "Our systems are currently offline. Please try again later";
        break;
      case "UNDER_MAINTENANCE":
        this.title = "Maintenance";
        this.description = "The application is currently in maintenance. Please try again later";
        break;
      default:
        this.title = "Operational";
        this.description = "Everything is OK";
        break;
    }
    this.next_maintenance = status.next_maintenance;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
