{
  "country": "SE",
  "language": "sv",
  "availableLanguages": [
    "sv"
  ],
  "staticLinkBoxes": [
    {
      "text": "Slutkund",
      "link": "https://www.viessmann.se/sv/service/produktinfo-ab.html?serial={serial}",
      "image": "assets/img/homeowner.jpg"
    },
    {
      "text": "Installatör",
      "link": "https://www.viessmann.se/sv/service/produktinfo-fb.html?serial={serial}",
      "image": "assets/img/professionals.jpg"
    }
  ]
}
