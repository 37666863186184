import { Component, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { FlagComponent } from './flag/flag.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '../translation.service';
import { DswFooterComponent } from '@vi-succ/design-system-web';
import { LanguageChangerComponent } from '../language-changer/language-changer.component';

@Component({
  selector: 'app-product-info',
  standalone: true,
  imports: [MatChipsModule, CommonModule, FlagComponent, TranslateModule, RouterModule, DswFooterComponent, LanguageChangerComponent],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './product-info.component.html',
  styleUrl: './product-info.component.scss'
})
export class ProductInfoComponent implements OnInit {
  dataString: string = "";
  public productInfo: any = [];
  public staticLinkBoxes: any;
  public language: string = "en";
  public availableLanguages = [];
  public salesOrganization = '';
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    
    private translationService: TranslationService
  ) {
    this.routeEvent(this.router);
  }

  public ngOnInit() {
    this.init();
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  private init() {
    this.productInfo = this.route.snapshot.data['productInformation'];
    this.translationService.setSalesOrganization(this.route.snapshot.params['salesOrganization']);
    this.translationService.setLanguage(this.route.snapshot.params['language']);
    this.translationService.setSerialNumber(this.productInfo.number);

    this.salesOrganization = this.translationService.getSalesOrganization();
    this.language = this.translationService.getLanguage();

    // i.e. assets/data/9900-en.json
    let data: any;
    try {
      data = require('../../assets/data/' + this.salesOrganization + '-' + this.language + '.json');
    } catch (e) {
      // fallback to default.json
      data = require('../../assets/data/default.json');
    }

    this.staticLinkBoxes = data.staticLinkBoxes;
    this.translationService.setCountry(data.country);
    this.translationService.setAvailableLanguages(data.availableLanguages);
  }
}
